/* css-side-effects-plugin-ns:app/components/spin/spin.css */
.spinner-wrapper {
  position: relative;
  display: inline-block;
}
.spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
/*# sourceMappingURL=/build/css-bundle-QUSI4XPE.css.map */
